import firebase from "firebase";

var dataConverter = {
  fromFirestore: function (snapshot, options) {
    return snapshot.data(options);
  },
};

const fetchData = (config, doc) => {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    db.collection(config)
      .doc(doc)
      .withConverter(dataConverter)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          reject(Error("document is empty "));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchMultiLevelData = (config, doc, collection, doc2) => {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    db.collection(config)
      .doc(doc)
      .collection(collection)
      .doc(doc2)
      .withConverter(dataConverter)
      .get()
      .then((content) => {
        if (content.exists) {
          resolve(content.data());
        } else {
          reject(Error("document is empty "));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { fetchData, fetchMultiLevelData };
