import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

class CountrySelector extends React.Component {
  onCountrySelection = (event, values) => {
    this.props.onCountrySelection(values);
  };

  getIndex(value, arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  render() {
    let index = this.getIndex(
      this.props.input.toCountry,
      this.props.allowedCountries
    );
    if (this.props.type == "1") {
      return (
        <Autocomplete
          id="disabled"
          options={this.props.allowedCountries}
          getOptionLabel={(option) => option}
          value={this.props.input.toCountry}
          disabled
          disableClearable
          onChange={this.onCountrySelection}
          renderInput={(params) => (
            <TextField
              {...params}
              label={this.props.label}
              variant="outlined"
            />
          )}
        />
      );
    } else {
      return (
        <Autocomplete
          id="disabled"
          options={this.props.allowedCountries}
          getOptionLabel={(option) => option}
          value={this.props.input.fromCountry}
          disableClearable
          getOptionDisabled={(option) =>
            option === this.props.allowedCountries[index]
          }
          onChange={this.onCountrySelection}
          renderInput={(params) => (
            <TextField
              {...params}
              label={this.props.label}
              variant="outlined"
            />
          )}
        />
      );
    }
  }
}

export default CountrySelector;
