import * as firebase from "firebase";
import allCountries from "./CountriesList";
import uploadIndiaData from "./IndiaData";

export default function firebaseDataUploader() {
  //addConfiguration();
}

function addConfiguration() {
  updateFromCountries();
  updateToCountries();
  updateHeader();
  updateFooter();
  uploadIndiaData();
}

function updateFromCountries() {
  var db = firebase.firestore();
  db.collection("config")
    .doc("allowedFromCountries")
    .set(FROM_COUNTRIES)
    .then(function () {
      console.log("Document successfully written!");
    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
    });
}

function updateToCountries() {
  var db = firebase.firestore();
  db.collection("config")
    .doc("allowedToCountries")
    .set(TO_COUNTRIES)
    .then(function () {
      console.log("Document successfully written!");
    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
    });
}

function updateHeader() {
  var db = firebase.firestore();
  db.collection("config")
    .doc("Header")
    .set(HEADER)
    .then(function () {
      console.log("Document successfully written!");
    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
    });
}

function updateFooter() {
  var db = firebase.firestore();
  db.collection("config")
    .doc("Footer")
    .set(FOOTER)
    .then(function () {
      console.log("Document successfully written!");
    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
    });
}

const FOOTER = {
  footers: [
    {
      type: "error",
      title:
        "Only for exceptional and compelling reasons such as cases of human distress, pregnancy, death in family, serious illness and parent(s) accompanied by children below 10 years, as assessed by the receiving states, home quarantine may be permitted for 14 days",
      description: "",
      buttonTitle: "Travel and Visa restrictions",
      url:
        "https://boi.gov.in/content/advisory-travel-and-visa-restrictions-related-covid-19-1",
    },
    // {
    //   type: "normal",
    //   title:
    //     "Stay aware of the latest information on the COVID-19 outbreak, available on the WHO website and through your national and local public health authority. Most countries around the world have seen cases of COVID-19 and many are experiencing outbreaks. Authorities in China and some other countries have succeeded in slowing their outbreaks. However, the situation is unpredictable so check regularly for the latest news.",
    //   description: "",
    //   buttonTitle: "Learn more from WHO",
    //   url:
    //     "https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public",
    // },
  ],
};

const HEADER = {
  header: [
    // {
    //   type: "error",
    //   title:
    //     "NOTE: All Scheduled international commercial passenger services to/from India shall remain suspended till 2359 hrs IST of 15th July, 2020.",
    //   description: "",
    //   buttonTitle: "Information for travellers",
    //   url:
    //     "https://dgca.gov.in/digigov-portal/jsp/dgca/homePage/viewPDF.jsp?page=topHeader/COVID/Circular(26June2020).pdf",
    // },
  ],
};

const LOCATION = {
  India: "India",
  Singapore: "Singapore",
  Australia: "Australia",
};

const FROM_COUNTRIES = {
  countries: allCountries(),
  default: LOCATION.Singapore,
};

const TO_COUNTRIES = {
  countries: [LOCATION.India],
  default: LOCATION.India,
};
