import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./ServiceWorker";
import * as firebase from "firebase";
import ReactGA from "react-ga";

const firebaseConfig = {
  apiKey: "AIzaSyD-wIeSW4a7JygYNmebVc7_911D0TQQv1Y",
  authDomain: "covidtravelplanner.firebaseapp.com",
  databaseURL: "https://covidtravelplanner.firebaseio.com",
  projectId: "covidtravelplanner",
  storageBucket: "covidtravelplanner.appspot.com",
  messagingSenderId: "1017980420066",
  appId: "1:1017980420066:web:d5da5706c3dba7dd0943bb",
  measurementId: "G-R1S0GH92YP",
};
firebase.initializeApp(firebaseConfig);
ReactGA.initialize("UA-172397462-1");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
