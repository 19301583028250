import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CountrySelector from "./CountrySelector";
import PersonOptions from "./PersionOptionsGroup";
import PropTypes from "prop-types";
import Timeline from "./Timeline";
import HeaderFooterCard from "./HeaderFooterCard";
import firebaseDataUploader from "./Firebase/FirebaseHelper";
import { fetchData, fetchMultiLevelData } from "./DataManager";
import { USERTYPE } from "./PersionOptionsGroup";
import localStorage from "local-storage";
import ReactGA from "react-ga";
import { Timeline as TwitterTimeline } from "react-twitter-widgets";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { ButtonGroup } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="mailto:chandan@frisbeelabs.com">
        CanFlyToIndia.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bigTitle: {
    fontSize: 22,
    color: "#ffffff",
    align: "left",
  },
  newsHeader: {
    borderRadius: 0,
    backgroundColor: "#00acee",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignInSide extends React.Component {
  state = {
    input: localStorage.get("input") ?? {
      fromCountry: "Singapore",
      toCountry: "India",
      personType: USERTYPE.Citizen.code,
    },
    allowedToCountries: [],
    allowedFromCountries: [],
    headers: [{}],
    footers: [{}],
    timelineList: [],
  };

  fetchTimeline() {
    fetchMultiLevelData(
      "timeline",
      this.state.input.toCountry,
      this.state.input.fromCountry,
      this.state.input.personType
    ).then((content) => {
      this.setState({
        timelineList: content.timeline,
      });
    });
  }

  componentDidMount() {
    firebaseDataUploader();

    let savedData = localStorage.get("input") ?? this.state.input;
    this.fetchTimeline();
    fetchData("config", "Header").then((content) => {
      this.setState({
        headers: content.header,
      });
    });

    fetchData("config", "Footer").then((content) => {
      this.setState({
        footers: content.footers,
      });
    });

    fetchData("config", "allowedToCountries").then((content) => {
      this.setState({
        allowedToCountries: content.countries,
        input: savedData,
      });
    });

    fetchData("config", "allowedFromCountries").then((content) => {
      this.setState({
        allowedFromCountries: content.countries,
        input: savedData,
      });
    });
  }

  onFromCountryChange = (value) => {
    this.setState(
      {
        input: {
          toCountry: this.state.input.toCountry,
          fromCountry: value,
          personType: this.state.input.personType,
        },
      },
      () => {
        ReactGA.event({
          category: "user",
          action: "from_country_pick",
          label: value,
        });
        localStorage.set("input", this.state.input);
        this.fetchTimeline();
      }
    );
  };

  onToCountryChange = (value) => {
    this.setState(
      {
        input: {
          toCountry: value,
          fromCountry: this.state.input.fromCountry,
          personType: this.state.input.personType,
        },
      },
      () => {
        ReactGA.event({
          category: "user",
          action: "to_country_pick",
          label: value,
        });
        localStorage.set("input", this.state.input);
        this.fetchTimeline();
      }
    );
  };

  onOptionsChange = (value) => {
    this.setState(
      {
        input: {
          toCountry: this.state.input.toCountry,
          fromCountry: this.state.input.fromCountry,
          personType: value,
        },
      },
      () => {
        ReactGA.event({
          category: "user",
          action: "option_change",
          label: value,
        });
        localStorage.set("input", this.state.input);
        this.fetchTimeline();
      }
    );
  };

  renderHeader() {
    let headers = this.state.headers.map(function (val) {
      return (
        <Grid item xs={12}>
          <HeaderFooterCard content={val} headerType={val.type} />
        </Grid>
      );
    });
    return headers;
  }

  renderFooter() {
    let footers = this.state.footers.map(function (val) {
      return (
        <Grid item xs={12}>
          <HeaderFooterCard content={val} headerType={val.type} />
        </Grid>
      );
    });
    return footers;
  }

  renderStatus() {
    if (this.state.timelineList.length <= 0) {
      return;
    }

    let status = this.state.timelineList[1];
    return (
      <Grid item xs={12}>
        <HeaderFooterCard status={status} headerType="nocta" />
      </Grid>
    );
  }

  resultContent(classes) {
    return (
      <Grid container square spacing={4}>
        {this.renderStatus()}
        {this.renderHeader()}
        <Grid item xs={12}>
          <Timeline
            content={this.state.input}
            timeline={this.state.timelineList}
          />
        </Grid>
        {this.renderFooter()}
      </Grid>
    );
  }

  renderShareButtons(classes) {
    const shareUrl = "https://canflytoindia.com";
    const title = `Helps to check whether traveling is allowed to ${this.state.input.toCountry} during COVID #CanFlyToIndia`;

    return (
      <div>
        {/* <div>
          <Typography variant="subtitle2" component="h2">
            Support by sharing
          </Typography>
        </div> */}
        <p>
          <ButtonGroup size="large">
            <div>
              <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>

            <div>
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>

            <div>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>

            <div>
              <EmailShareButton
                url={shareUrl}
                subject="Check this website"
                body={title}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>

            <div>
              <TelegramShareButton url={shareUrl} title={title}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
          </ButtonGroup>
        </p>
      </div>
    );
  }

  singInContent(classes) {
    return (
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <FlightTakeoffIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Can Fly To India
        </Typography>

        <form className={classes.form} noValidate>
          <p>
            <CountrySelector
              type="1"
              label="I want to go to"
              input={this.state.input}
              allowedCountries={this.state.allowedToCountries}
              onCountrySelection={this.onToCountryChange}
            />
          </p>

          <p>
            <CountrySelector
              type="2"
              label="From"
              input={this.state.input}
              allowedCountries={this.state.allowedFromCountries}
              onCountrySelection={this.onFromCountryChange}
            />
          </p>

          <Box mt={5} justify="flex-start" alignItems="center">
            <PersonOptions
              input={this.state.input}
              onOptionsSelection={this.onOptionsChange}
            />
          </Box>
        </form>
        <p>{this.renderShareButtons(classes)}</p>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={2} component={Paper} elevation={0} square>
          <Grid item xs={12} sm={5}>
            {this.singInContent(classes)}
          </Grid>
          <Grid item xs={12} sm={7}>
            {this.resultContent(classes)}
          </Grid>
        </Grid>

        {this.renderTwitterFeed(classes)}

        <Box mt={5} direction="row" justify="flex-start" alignItems="center">
          <p>
            <Copyright />
          </p>
        </Box>
      </div>
    );
  }

  renderTwitterFeed(classes) {
    const TWITTER_HCI = {
      Singapore: "IndiainSingapor",
      "United Kingdom": "HCI_London",
      "United States": "IndianEmbassyUS",
      China: "EOIBeijing",
      Australia: "HCICanberra",
      "United Arab Emirates": "IndembAbuDhabi",
      Sweden: "indiainsweden",
      Canada: "HCI_Ottawa",
    };
    let twitterLink = TWITTER_HCI[this.state.input.fromCountry];
    if (twitterLink == null) {
      return;
    }

    return (
      <Box direction="row" alignItems="center">
        <p>
          <Card className={classes.newsHeader} variant="outlined">
            <CardContent>
              <Typography className={classes.bigTitle}>
                Latest Tweets
              </Typography>
            </CardContent>
          </Card>
        </p>

        <TwitterTimeline
          dataSource={{
            sourceType: "profile",
            screenName: twitterLink,
          }}
        />
      </Box>
    );
  }
}

SignInSide.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignInSide);
