import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export const USERTYPE = {
  Citizen: { name: "Citizen", code: "0" },
  PR: { name: "Permanent Resident", code: "1" },
  Tourist: { name: "Tourist", code: "2" },
};

class PersionOptionsGroup extends React.Component {
  handleChange = (event) => {
    this.value = event.target.value;
    this.props.onOptionsSelection(this.value);
  };

  render() {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="person"
          name="Person"
          value={this.props.input.personType}
          onChange={this.handleChange}
        >
          <FormControlLabel
            value={USERTYPE.Citizen.code}
            control={<Radio />}
            label={`Citizen of ${this.props.input.toCountry}`}
          />
          {/* <FormControlLabel
            value={USERTYPE.PR.code}
            control={<Radio />}
            label={USERTYPE.PR.name}
          /> */}
          <FormControlLabel
            value={USERTYPE.Tourist.code}
            control={<Radio />}
            label={USERTYPE.Tourist.name}
          />
        </RadioGroup>
      </FormControl>
    );
  }
}
export default PersionOptionsGroup;
