import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactGA from "react-ga";

const styles = (theme) => ({
  root: {
    minWidth: 275,
    backgroundColor: theme.palette.primary,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  allowedCard: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
  notAllowedCard: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    fontSize: 14,
  },
  bigTitle: {
    fontSize: 24,
    color: "#ffffff",
  },
  pos: {
    marginBottom: 12,
  },
  warning: {
    fontSize: 14,
    color: theme.palette.warning.main,
  },
  error: {
    fontSize: 14,
    color: theme.palette.error.main,
  },
});

const WarningTextTypography = withStyles({
  root: {
    color: "#d32f2f",
  },
})(Typography);

class HeaderFooterCard extends React.Component {
  constructor(props) {
    super(props);
  }

  renderPlainHeader(allowed = true) {
    const { classes } = this.props;
    let classTheme =
      this.props.status.color == "primary"
        ? classes.allowedCard
        : classes.notAllowedCard;
    return (
      <Card className={classTheme} variant="outlined">
        <CardContent>
          <Typography className={classes.bigTitle} color="primary">
            {this.props.status.title}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    var classTheme = classes.title;
    if (this.props.headerType == "warning") {
      classTheme = classes.warning;
    } else if (this.props.headerType == "error") {
      classTheme = classes.error;
    }

    if (this.props.headerType == "nocta") {
      return this.renderPlainHeader();
    } else {
      return (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classTheme}
              color="textSecondary"
              align="justify"
              gutterBottom
            >
              {this.props.content.title}
            </Typography>
          </CardContent>
          <CardActions justify="center">
            <Button
              variant="outlined"
              color="primary"
              href="#outlined-buttons"
              onClick={() => {
                ReactGA.event({
                  category: "user",
                  action: "learn_more",
                  label: this.props.content.buttonTitle,
                });
                window.open(this.props.content.url); //to open new page
              }}
            >
              {this.props.content.buttonTitle}
            </Button>
          </CardActions>
        </Card>
      );
    }
  }
}

export default withStyles(styles)(HeaderFooterCard);
