import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import HomeIcon from "@material-ui/icons/Home";
import HotelIcon from "@material-ui/icons/Hotel";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AirplanemodeInactiveIcon from "@material-ui/icons/AirplanemodeInactive";
import NoteIcon from "@material-ui/icons/Note";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import Link from "@material-ui/core/Link";
import WarningIcon from "@material-ui/icons/Warning";
import ReactGA from "react-ga";

const styles = (theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
});

class CustomizedTimeline extends React.Component {
  renderIcon(icon) {
    if (icon == "home") {
      return <HomeIcon />;
    } else if (icon == "hotel") {
      return <HotelIcon />;
    } else if (icon == "flight") {
      return <FlightTakeoffIcon />;
    } else if (icon == "place") {
      return <LocationCityIcon />;
    } else if (icon == "info") {
      return <NoteIcon />;
    } else if (icon == "hospital") {
      return <LocalHospitalIcon />;
    } else if (icon == "moodbad") {
      return <AirplanemodeInactiveIcon />;
    } else if (icon == "warning") {
      return <WarningIcon />;
    } else {
      return "";
    }
  }

  renderSeperator(isLast) {
    return !isLast ? <TimelineConnector /> : "";
  }

  renderTimelineItem(
    title,
    description = "",
    icon,
    isLast = true,
    color = "secondary",
    link = { title: "", url: "" }
  ) {
    const { classes } = this.props;
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color={color}>{this.renderIcon(icon)}</TimelineDot>
          {this.renderSeperator(isLast)}
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="subtitle1" component="h1">
              {title}
            </Typography>
            <Typography>
              <Link
                href="#"
                onClick={() => {
                  window.open(link.url);
                  ReactGA.event({
                    category: "user",
                    action: "timeline_click",
                    label: link.title,
                  });
                }}
              >
                {link.title}
              </Link>
            </Typography>
            <Typography>{description}</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  }

  renderList() {
    return this.props.timeline.map((val) => {
      return this.renderTimelineItem(
        val.title,
        val.description,
        val.icon,
        val.isLast,
        val.color,
        val.link
      );
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root} variant="outlined">
        <Timeline align="alternate">{this.renderList()}</Timeline>
      </Card>
    );
  }
}

CustomizedTimeline.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CustomizedTimeline);
